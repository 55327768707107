import React, { useEffect, useState } from "react";
import Navigation from "./Navigation";
import classes from "./MainHeader.module.css";
import { useContext } from "react";
import { Context } from "../../context/Context";
// import MuiAppBar from "@mui/material/AppBar";
import {
  Button,
  Chip,
  Icon,
  styled,
  ThemeProvider,
  useTheme,
} from "@mui/material";
// import SendIcon from "@mui/icons-material/Send";
import { Link } from "react-router-dom";

const MainHeader = (props) => {
  const theme = useTheme();
  const { dispatch, user } = useContext(Context);

  const username = !user ? "Flugel Educare" : user.username;
  return (
    <header className={classes["main-header"]}>
      <h1>{username}</h1>
      {user
        ? user.username !== "admin" && (
            <Link to="/compose" style={{textDecoration:"none"}}><button  className={classes.message_button}>
              Message to Admin .... 
            </button></Link>
          )
        : ""}

      <Navigation
        isLoggedIn={props.isAuthenticated}
        onLogout={props.onLogout}
      />
      {/* </Main>
    </Box> */}
    </header>
  );
};

export default MainHeader;
