import React, { useEffect, useState } from "react";
import classes from "./AdminMessages.module.css";
import AdminNavbar from "./AdminNavbar";
import {
  Table,
  TableHead,
  TableCell,
  Paper,
  TableRow,
  TableBody,
  Button,
  styled,
  useTheme,
  TableContainer,
  TablePagination,
} from "@mui/material";
import { Link } from "react-router-dom";

// import axios from "axios";
import { deleteMessage, getMessages } from "../../Service/api";
const THead = styled(TableRow)`
  & > th {
    font-size: 16px;
    background: #aabfd1;
    color: #ffffff;
  }
`;
const AdminMessages = () => {
  const [message, setMessage] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    console.log(event.target.value);
    setRowsPerPage(+event.target.value);
    setPage(0);
  };



  useEffect(() => {
    getAllMessage();
  }, []);

  const getAllMessage = async () => {
    let response = await getMessages();
    // console.log(message);
    setMessage(response.data);
  };

  const deleteUserData = async (id) => {
    await deleteMessage(id);
    getAllMessage();
  };
  return (
    <div className={classes.admin_message_container}>
      <div className={classes.admin_message_navbar}>
        <AdminNavbar />
      </div>
      
      <div className={classes.message_table}>
        <div className={classes.message_table_item1}>
      <Paper
        sx={{
          width: "70%",
          overflow: "hidden",
          marginLeft: 5,
          marginTop: 18,
        }}
      >
        <TableContainer sx={{ maxHeight: 550 }}>
          <Table className={classes.table} sx={{ width: "100%" }} stickyHeader aria-label="sticky table">
            <TableHead>
              <THead>
                <TableCell
                className={classes.t1}
                  sx={{
                    width: "10%",
                  }}
                >
                  Sl. No.
                </TableCell>
                <TableCell className={classes.t1} sx={{
                    width: "15%",
                  }}>Name</TableCell>
                <TableCell className={classes.t1} sx={{
                    width: "65%",
                  }}>Message</TableCell>

                <TableCell className={classes.t1}></TableCell>
              </THead>
            </TableHead>
            <TableBody sx={{ height: 10 }}>
              {message.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((user, index) => {
                return (
                  <TableRow
                    key={user._id}
                    sx={{
                      height: 20,
                      background: "rgba(255, 255, 255, 0.22)",
                      boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
                      backdropFilter: "blur(5.2px)",
                      border: "1px solid rgba(255, 255, 255, 0.87)"
                    }}
                  >
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{user.name}</TableCell>
                    <TableCell>{user.message}</TableCell>

                    <TableCell>
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={() => deleteUserData(user._id)}
                      >
                        Delete
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      </div>
      

      <TablePagination
            className={classes.pagination}
            rowsPerPageOptions={[3, 5, 8]}
            component="div"
            count={message.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            />
          
          </div>
    </div>
  );
};

export default AdminMessages;
