// import Context from "@mui/base/TabsUnstyled/TabsContext";

import React, { useContext, useState } from "react";
import { NavLink } from "react-router-dom";
import { Context } from "../../context/Context";
import './AdminNavbar.css'

const dashboard = "img/dashboard.svg"
const location ="img/city.svg"
const manageuser="img/users.svg"
const students = "img/college-class.svg"
const messages = "img/messages.svg"
const process = 'img/planning.png'



const AdminNavbar = ({ li }) => {
  const [window, setWindow] = useState(false);
  const {user} = useContext(Context);

  let openClose = () => {
    if (window === false) {
      setWindow(true);
    } else {
      setWindow(false);
    }
  };
  return (
    <nav className="navbar-menu" style={{ width: window === false ? 180 : 60 }}>
      <div className="burger" onClick={() => openClose()}>
        <img src="img/menu.svg" alt="burger" />
      </div>
      <ul className="navbar__list">
        {user.username==='admin'&&<NavLink to='/AdminHome'>
          <div className="navbar__li-box" onClick={() => setWindow(true)} >
            <img
              src={dashboard}
              alt={dashboard}
              style={{ paddingLeft: window === false ? 20 : 14 }}
              className="navbar__li-dashboard"
            />
            <li
              className="navbar__li"
              style={{ display: window === false ? "inline-block" : "none" }}
            >
              Dashboard
            </li>
          </div>
          </NavLink>}
          {user.username==='admin'&&
          <NavLink to='/centers'>
          <div className="navbar__li-box" onClick={() => setWindow(true)} >
            <img
              src={location}
              alt={location}
              style={{ paddingLeft: window === false ? 20 : 5 }}
              className="navbar__li-location"
            />
            <li
              className="navbar__li"
              style={{ display: window === false ? "inline-block" : "none" }}
            >
              Center
            </li>
          </div>
          </NavLink>}
          {user.username==='admin'&&
         
          <NavLink to='/adminadduser'>
          <div className="navbar__li-box" onClick={() => setWindow(true)} >
            <img
              src={manageuser}
              alt={manageuser}
              style={{ paddingLeft: window === false ? 20 : 5 }}
              className="navbar__li-user"
            />
            <li
              className="navbar__li"
              style={{ display: window === false ? "inline-block" : "none" }}
            >
              Manage User
            </li>
          </div>
          </NavLink>}
          {user.username==='admin'&&
          <NavLink to='/adminstudent'>
          <div className="navbar__li-box" onClick={() => setWindow(true)} >
            <img
              src={students}
              alt={students}
              style={{ paddingLeft: window === false ? 20 : 5 }}
              className="navbar__li-img"
            />
            <li
              className="navbar__li"
              style={{ display: window === false ? "inline-block" : "none" }}
            >
              Students
            </li>
          </div>
          </NavLink>}

          {user.username==='manager'&&
          <NavLink to='/adminstudentprocess'>
          <div className="navbar__li-box" onClick={() => setWindow(true)} >
            <img
              src={process}
              alt={process}
              style={{ paddingLeft: window === false ? 20 : 5 }}
              className="navbar__li-process"
            />
            <li
              className="navbar__li"
              style={{ display: window === false ? "inline-block" : "none" }}
            >
              Student Status
            </li>
          </div>
          </NavLink>}
          {user.username==='admin'&&

          <NavLink to='/messageS'>
          <div className="navbar__li-box" onClick={() => setWindow(true)} >
            <img
              src={messages}
              alt={messages}
              style={{ paddingLeft: window === false ? 20 : 5 }}
              className="navbar__li-img"
            />
            <li
              className="navbar__li"
              style={{ display: window === false ? "inline-block" : "none" }}
            >
              Messages
            </li>
          </div>
          </NavLink>}
          
        
      </ul>
    </nav>
  );
};

export default AdminNavbar;