import React from "react";
import classes from './AdminCard.module.css'
import CardContent from "@mui/material/CardContent";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";

const AdminCard = (props) => {
    const fee=0;
    const balance=0;
  return (
    <div className={classes.card}>
      <Card className={classes.card_box} sx={{ minWidth: 170, background: "rgba(221, 221, 221, .3)",borderRadius:"10px", boxShadow:" 0 4px 30px rgba(0, 0, 0, 0.1)", backdropFilter: "blur(12px)", border:" 1px solid rgba(255, 255, 255, 0.5)", cursor:"pointer"}}>
        <CardContent>
          <Typography variant="h5" sx={{color:"#132f08"}} component="div">
            {props.name}
          </Typography>
          <Typography sx={{ mb: 1, color: "#132f08" }} color="text.secondary">
            Total Fee Paid
          </Typography>
          <Typography sx={{ mb: 1,color:"#00539b" }} color="text.secondary">
            Rs.{props.fee}
          </Typography>
          <Typography sx={{ mb: 1, color: "	#351c75" }} color="text.secondary">
            Total Pay Balance
          </Typography>
          <Typography sx={{ mb: 1,color: "#00539b"  }} color="text.secondary">
            Rs.{props.bal}
          </Typography>
        </CardContent>
      </Card>
    </div>
  );
};
export default AdminCard;
