import React, { useEffect, useState } from "react";
import classes from './AdminUserList.module.css'
import { getadminusers } from "../../Service/api";
import { Link } from "react-router-dom";
import {
  Card,
  NativeSelect,
  TableContainer,
  TablePagination,
} from "@mui/material";

import { useContext } from "react";
import { Context } from "../../context/Context";

// import { styled, useTheme } from '@mui/material/styles';

import {
  Table,
  TableHead,
  TableCell,
  Paper,
  TableRow,
  TableBody,
  Button,
  styled,
  useTheme,
} from "@mui/material";
import AdminNavbar from "./AdminNavbar";
// import AddStudent from "../AddStudent/AddStudent";

const StyledTable = styled(Table)`
  width: 90%;
  margin: 50px 0 0 50px;
`;

const THead = styled(TableRow)`
  & > th {
    font-size: 16px;
    background: #3e426d;
    color: #ffffff;
  }
`;

const TRow = styled(TableRow)`
  & > td {
    font-size: 15px;
    background: #cce1f5;
  }
`;

const AdminUserList = () => {
  const [isEditing, setIsEditing] = useState(false);
  const { user } = useContext(Context);
  const [users, setUsers] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    getAllUsers();
  }, []);

  const getAllUsers = async (username) => {
    let response = await getadminusers();
    setUsers(response.data);
    // console.log(users);
  };

  return (
    <div className={classes.container}>
       <div className={classes.navbar}>

        <AdminNavbar />
       </div>
      
      <div className={classes.table}>
        {!isEditing && (
          <>
            <Paper
              sx={{
                width: "80%",
                overflow: "hidden",
                marginLeft: 5,
                marginTop: 18,
              }}
            >
              <TableContainer sx={{ maxHeight: 500 }}>
                <Table
                  sx={{ width: "100%" }}
                  stickyHeader
                  aria-label="sticky table"
                >
                  <TableHead>
                    <THead>
                      <TableCell>Sl. No.</TableCell>
                      <TableCell>User Name</TableCell>
                      <TableCell>Password</TableCell>

                      <TableCell></TableCell>
                    </THead>
                  </TableHead>
                  <TableBody sx={{ height: 20 }}>
                    {users.map((user, index) => {
                      return (
                        <TableRow
                          key={user._id}
                          sx={{
                            height: 20,
                            backgroundColor:
                              " linear-gradient(90deg, rgba(243,239,246,1) 0%, rgba(210,204,204,1) 50%, rgba(255,242,223,1) 100%)",
                          }}
                        >
                          <TableCell>{index + 1}</TableCell>
                          <TableCell>{user.username}</TableCell>
                          <TableCell>{user.password}</TableCell>

                          <TableCell>
                            <Button
                              color="primary"
                              variant="contained"
                              style={{ marginRight: 10 }}
                              component={Link}
                              to={`/editadminuser/${user._id}`}
                            >
                              Edit
                            </Button>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </>
        )}
      </div>
    </div>
  );
};

export default AdminUserList;
