import React, { useReducer, useState } from "react";
import loginImage from "../../images/17343-programming.gif";
import Card from "../UI/Card/Card";
import classes from "./Login.module.css";
import Button from "../UI/Button/Button";
// import { loginUser } from "../../Service/api";
import { useContext } from "react";
import { Context } from "../../context/Context";
import axios from "axios";
// import { scryRenderedDOMComponentsWithClass } from "react-dom/test-utils";
const usersUrl = "https://flugeleducare-server1.onrender.com";

const userReducer = (state, action) => {
  if (action.type === "USER_INPUT") {
    return {
      name: action.name,
      value: action.val,
      isValid: action.val.trim().length > 0,
    };
  }
  if (action.type === "USER_BLUR") {
    return {
      name: state.name,
      value: state.value,
      isValid: state.value.trim().length > 0,
    };
  }
  return { name: "", value: "", isValid: false };
};
const passwordReducer = (state, action) => {
  if (action.type === "USER_INPUT") {
    return {
      name: action.name,
      value: action.val,
      isValid: action.val.trim().length > 0,
    };
  }
  if (action.type === "USER_BLUR") {
    return {
      name: state.name,
      value: state.value,
      isValid: state.value.trim().length > 0,
    };
  }
  return { name: "", value: "", isValid: false };
};
const defaultValue = {
  userName: "",
  password: "",
};

const Login = (props) => {
  const { dispatch, isFetching } = useContext(Context);
  const [user, setUser] = useState(defaultValue);
  const [formIsValid, setFormIsValid] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  
  const [userState, dispatchUser] = useReducer(userReducer, {
    name: "",
    value: "",
    isValid: null,
  });
  const [passwordState, dispatchPassword] = useReducer(passwordReducer, {
    name: "",
    value: "",
    isValid: null,
  });

  const userNameChangeHandler = (event) => {
    // setEnteredUserName(event.target.value);
    setUser({ ...user, [event.target.name]: event.target.value });
    // console.log(user);
    dispatchUser({
      type: "USER_INPUT",
      name: event.target.name,
      val: event.target.value,
    });

    setFormIsValid(event.target.value.length > 0 && passwordState.isValid);
  };

  const passwordChangeHandler = (event) => {
    // setEnteredPassword(event.target.value);
    setUser({ ...user, [event.target.name]: event.target.value });
    // console.log(user);
    dispatchPassword({
      type: "USER_INPUT",
      name: event.target.name,
      val: event.target.value,
    });

    setFormIsValid(event.target.value.trim().length > 0 && userState.isValid);
  };

  const validateUserNameHandler = () => {
    // setUserNameIsValid(userState.isValid);
    dispatchUser({ type: "USER_BLUR" });
  };

  const validatePasswordHandler = () => {
    // setPasswordIsValid(passwordState.isValid);
    dispatchPassword({ type: "USER_BLUR" });
  };

  const submitHandler = async (event) => {
    event.preventDefault();
    dispatch({ type: "LOGIN_START" });
   
    try {
      const res = await axios.post(`${usersUrl}/login`, user);
      // console.log(res.data.username);
      // props.onLoginUsername(res.data.username)
      // console.log(res.data);
      // props.onLogin(true)

      dispatch({ type: "LOGIN_SUCCESS", payload: res.data });
     
      // let admin =res.data.admin
      // localStorage.setItem('admin',admin)
      // localStorage.setItem('login',true)

      // props.onLogin(true)

      
    } catch (error) {
      console.log(error);
      dispatch({ type: "LOGIN_FAILURE" });

      setErrorMsg("Invalid username or password!.");
    }
  };

  return (
    <div className={classes.loginImageContainer}>
      <Card className={classes.login}>
        <h2 className={classes.logintext}>User Login</h2>
        <form onSubmit={submitHandler}>
          <div
            className={`${classes.control} 
            ${userState.isValid === false ? classes.invalid : ""}`}
            >
            <label htmlFor="userName">User name</label>
            <input
              type="text"
              id="userName"
              name="userName"
              value={userState.value}
              onChange={userNameChangeHandler}
              required
              onBlur={validateUserNameHandler}
              />
          </div>
          <div
            className={`${classes.control}
            ${passwordState.isValid === false ? classes.invalid : ""}`}
            >
            <label htmlFor="password">Password</label>
            <input
              type="password"
              id="password"
              value={passwordState.value}
              name="password"
              required
              onChange={passwordChangeHandler}
              onBlur={validatePasswordHandler}
              />
          </div>
          <div className={classes.actions}>
            <Button
              type="submit"
              // className={classes.btn}
              // disabled={!formIsValid}
              disabled={isFetching}
              >
              Login
            </Button>
          </div>
          <div style={{ color: "red" }}>
            <p>{errorMsg}</p>
          </div>
        </form>
      </Card>
      <div className={classes.loginImageContainer}>
        <img src={loginImage} alt="login" className={classes.loginImage}></img>
      </div>
    </div>

            
  );
};

export default Login;
