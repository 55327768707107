import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { getUsers, editUser, getadminusers, editUserlist, getadminuser } from "../../Service/api";
import Button from "../UI/Button/Button";
import classes from "./EditUser.module.css";
import Card from "../UI/Card/Card";

const defaultValue = {
  _id:"",
  username: "",
  password: "",
};

const EditUser = (props) => {
  const [enteredUserName, setEnteredUserName] = useState("");
  const [enteredPassword, setEnteredPassword] = useState("");
  const [user, setUser] = useState(defaultValue);
  const { _id,username, password } = user;
  const { id } = useParams();

  let history = useHistory();

  useEffect(() => {
    loadUserDetails();
  }, []);
// console.log(id);
  const loadUserDetails = async () => {
    
    let response = await getadminuser(id);
    setUser(response.data);
    console.log(response.data);
  };

  const userNameChangeHandler = (event) => {
    setEnteredUserName(event.target.value);
    setUser({ ...user, [event.target.name]: event.target.value });
  };

  const passwordChangeHandler = (event) => {
    setEnteredPassword(event.target.value);
    setUser({ ...user, [event.target.name]: event.target.value });
  };

  const editUserDetails = async (e) => {
    e.preventDefault();
console.log(enteredUserName);
    const userData = {
      _id:_id,
      username: enteredUserName ? enteredUserName : username,
      password: enteredPassword ? enteredPassword : password,
    };
    history.push("/adminadduser");
    const response = await editUserlist(id, userData);
  };

  return (
    <Card className={classes.form}>
      <div className={classes["student-data-form"]}>
        <form>
          <div className={classes["form-control"]}>
            <div className={`${classes.control}  `}>
              <label htmlFor="userName">User name</label>
              <input
                type="text"
                id="userName"
                value={username}
                onChange={userNameChangeHandler}
                // onBlur={validateUserNameHandler}
                name="username"
              />
            </div>
            <div className={`${classes.control} `}>
              <label htmlFor="password">Password</label>
              <input
                type="text"
                id="password"
                value={password}
                onChange={passwordChangeHandler}
                // onBlur={validateCourseNameHandler}
                name="password"
              />
            </div>

            <div className={classes.actions}>
              <Button
                type="submit"
                className={classes.button}
                // disabled={!formIsValid}
                onClick={editUserDetails}
              >
                Edit user
              </Button>
              <Button
                type="button"
                onClick={() => {
                  history.push("/adminadduser");
                }}
              >
                Cancel
              </Button>
            </div>
          </div>
        </form>
      </div>
    </Card>
  );
};

export default EditUser;
