import React, { useContext } from 'react'
import { Context } from '../../context/Context';
import AdminNavbar from '../AdminHome/AdminNavbar';
import StudentProcess from '../AdminHome/StudentProcess';
import classes from './ManagerHome.module.css'

const ManagerHome = () => {
    const { dispatch,user } = useContext(Context);
  return (
    <div className={classes.adminstudent}>
        {/* <div >
        <AdminNavbar />
      </div> */}
      <div className={ classes.adminstudent__container}>
        <StudentProcess />
      </div>

    </div>
  )
}

export default ManagerHome