import React, { useState } from "react";

import Login from "./components/Login/Login";
import Home from "./components/Home/Home";
import MainHeader from "./components/MainHeader/MainHeader";
// import AdminLogin from "./components/AdminLogin/AdminLogin";
import { useContext } from "react";
import { Context } from "./context/Context";
import EditStudent from "./components/EditStudent/EditStudent";
// import {
//   BrowserRouter as Router,

//   Route,
//   Link,
// } from "react-router-dom";

import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import AdminHome from "./components/AdminHome/AdminHome";
import AdminLogin from "./components/AdminLogin/AdminLogin";
import AdminCenters from "./components/AdminHome/AdminCenters";
import AdminAddUser from "./components/AdminHome/AdminAddUser";
import AdminStudent from "./components/AdminHome/AdminStudents";
import AdminUserList from "./components/AdminHome/AdminUserList";
import EditUser from "./components/AdminHome/EditUser";
import Compose from "./components/Compose/Compose";
import AdminMessages from "./components/AdminHome/AdminMessages";
import Footer from "./components/Footer/Footer";
import AdminStudentProcess from "./components/AdminHome/AdminStudentProcess";
import EditStudentProcess from "./components/AdminHome/EditStudentProcess";
import ManagerHome from "./components/ManagerHome/ManagerHome";
import ManagerLogin from "./components/ManagerLogin/ManagerLogin";
// import Chart from "./components/AdminHome/AdminDashboard";

function App() {
  const { user } = useContext(Context);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [admin1, setAdmin1] = useState(null);
  const [username, setUsername] = useState("");
  // if(user.admin){
  //   setAdmin(true)
  // }else if(!user.admin){
  //   setAdmin(false)
  // }
  // console.log(user);

  const [studentData, setStudentData] = useState([]);

  const loginHandler = (email, password) => {
    // We should of course check email and password
    // But it's just a dummy/ demo anyways
    setIsLoggedIn(true);
  };

  const logoutHandler = () => {
    setIsLoggedIn(false);
  };

  // const studentDataHandler = (data) => {
  //   setStudentData(() => {
  //     return [data];
  //   });
  // };
  // console.log(studentData);
  const userAdminHandler = (data) => {
    // console.log(data);
    setAdmin1(data);
  };
  const usernameChangehandler = (data) => {
    console.log(data);
    setUsername(data);
  };

  return (
    <Router>
      <MainHeader
        isAuthenticated={isLoggedIn}
        onLogout={logoutHandler}
        username={username}
      />
      <Switch>
        <Route exact path="/">
          <Home onLoginHand={usernameChangehandler} />
        </Route>
        {/* <Route exact path="/" render={() => <Home />} /> */}
        <Route path="/login" render={() => (!user ? <Login /> : <Home />)} />

        <Route
          path="/adminlogin"
          render={() => (!user ? <AdminLogin /> : <AdminHome />)}
        />
         <Route
          path="/managerlogin"
          render={() => (!user ? <ManagerLogin /> : <ManagerHome />)}
        />

        <Route path="/edit/:id" render={() => <EditStudent />} />
        <Route path="/managerhome" render={() => <ManagerHome />} />
        <Route path="/editprocess/:id" render={() => <EditStudentProcess />} />
        <Route path="/edituser/:id" render={() => <EditUser />} />
        <Route path="/centers" render={() =><AdminCenters/>} />
        <Route path="/adminadduser" render={() => (!user ? <AdminLogin /> :<AdminUserList />)} />
        <Route path="/editadminuser/:id" render={()=>(!user?<AdminLogin/>:<EditUser/>)}/>
        <Route path="/adminhome" render={() => (!user ? <AdminLogin /> :<AdminHome />)} />
        <Route path="/adminstudent" render={() => (!user ? <AdminLogin /> :<AdminStudent />)} />
        <Route path="/adminstudentprocess" render={() => (!user ? <AdminLogin /> :<AdminStudentProcess />)} />
        <Route path="/compose" render={() => (!user ? <Login /> :<Compose />)} />
        <Route path="/messageS" render={() => (!user ? <AdminLogin /> :<AdminMessages />)} />
       

        {isLoggedIn && <Home onLogout={logoutHandler} />}
      </Switch>
      <Footer/>
    </Router>
  );
}

export default App;
