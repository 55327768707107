import React from "react";
import {  styled } from "@mui/material";
// import { BrowserRouter as Router,NavLink } from "react-router-dom";
import { Link } from "react-router-dom";

import classes from "./Navigation.module.css";
import { useContext } from "react";
import { Context } from "../../context/Context";
import { useHistory } from "react-router-dom";
// const Header = styled(AppBar)`
//   background: #1174ed;
// `;

const Tabs = styled(Link)`
text-decoration: none;
color: white;
`;


const Navigation = (props) => {
  const { dispatch,user } = useContext(Context);
  // console.log(user);
  const handleLogout=()=>{
    dispatch({type:"LOGOUT"})
    localStorage.setItem('login',false)
    if(user.username==="admin") history.push("/adminhome")
    else if(user.username==="manager")history.push("/managerlogin")
    else history.push("/");
    
  }

  let history = useHistory();
  // const [admin,setAdmin]=useState(false)
  // const adminHandler=()=>{
  // setAdmin(true)
  // props.admin(admin)
  // }
  return (
    <nav className={classes.nav}>
      <ul>
        {/* <Router> */}
        
        {!user && (
          <li>
            {/* <a href="/">Users</a> */}

            <Tabs to="/login">User</Tabs>
          </li>
        )}
        {!user && (
          <li>
            {/* <a href="/">Users</a> */}

            <Tabs to="/managerlogin">Manager</Tabs>
          </li>
        )}
        {!user && (
          <li>
            <Tabs to="/adminlogin" >Admin</Tabs>
            {/* <a href="/">Admin</a> */}
          </li>
        )}
        {/* </Router> */}
        {user && (
          <li>
            <button onClick={handleLogout}>Logout</button>
          </li>
        )}
      </ul>
    </nav>
    // <Header>
    //   <Toolbar>
    //     {!props.isLoggedIn && (
    //       <Tabs>Users</Tabs>
    //     )}

    //     {!props.isLoggedIn && (
    //      <Tabs>Admin</Tabs>
    //     )}
    //     {props.isLoggedIn && (
    //       <li>
    //         <button onClick={props.onLogout}>Logout</button>
    //       </li>
    //     )}
    //   </Toolbar>
    // </Header>
  );
};

export default Navigation;
