import React from 'react'
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { getUsers, editUser } from "../../Service/api";
import Button from "../UI/Button/Button";
import classes from "./EditStudentProcess.module.css";
import Card from "../UI/Card/Card";
import AdminNavbar from './AdminNavbar';
import StudentProcess from './StudentProcess';
const defaultValue = {
  admissionnumber: "",
  studentname: "",
  coursename: "",
  coursefee: "",
  joiningdate: "",
  cordinationplace: "",
  feepaid: "",
  paybalance: "",
  phonenumber:"",
  emailid:"",
  company:"",
  placement:"",
  acceptance:"",
  b2exam:"",
  visadate:""
};


const EditStudentProcess = () => {
 
const [enteredPhoneNumber, setEnteredPhoneNumber] = useState("");
// const [phoneNumberIsValid, setPhoneNumberIsValid] = useState();
const [enteredEmailId, setEnteredEmailId] = useState("");
// const [EmailIdIsValid, setEmailIdIsValid] = useState();
const [enteredCompany, setEnteredCompany] = useState("");
// const [CompanyIsValid, setCompanyIsValid] = useState();
const [enteredAcceptance, setEnteredAcceptance] = useState("");
// const [acceptanceIsValid, setAcceptanceIsValid] = useState();
const [enteredB2exam, setEnteredB2exam] = useState("");
// const [b2examIsValid, setB2examIsValid] = useState();
const [enteredVisaDate, setEnteredVisaDate] = useState("");
// const [visaDateIsValid, setVisaDateIsValid] = useState();
const [enteredPlacement, setEnteredPlacement] = useState("");
// const [placementIsValid, setPlacementIsValid] = useState();


// const [formIsValid, setFormIsValid] = useState(false);



const [user, setUser] = useState(defaultValue);
const {
  admissionnumber,
  studentname,
  coursename,
  joiningdate,
  cordinationplace,
  feepaid,
  paybalance,
  phonenumber,
  emailid,
  company,
  placement,
  acceptance,
  b2exam,
  visadate,
  username,
  coursefee
  
} = user;

const { id } = useParams();
// console.log(id);

let history = useHistory();

useEffect(() => {
  loadUserDetails();
  // setPaybal(user.paybalance)
}, []);

const loadUserDetails = async () => {
  const response = await getUsers(id);
  setUser(response.data);
};

// const userNameChangeHandler = (event) => {
//   setEnteredUserName(event.target.value);
//   // setUser({ ...user, [event.target.name]: event.target.value });

 
// };

const emailidChangeHandler = (event) => {
  setEnteredEmailId(event.target.value);
  setUser({ ...user, [event.target.name]: event.target.value })
  // setUser({ ...user, [event.target.name]: event.target.value });
  // setStudent({ ...student, [event.target.name]: event.target.value });

 
};

const companyChangeHandler = (event) => {
  setEnteredCompany(event.target.value);
  setUser({ ...user, [event.target.name]: event.target.value })
  // setUser({ ...user, [event.target.name]: event.target.value });
  // setStudent({ ...student, [event.target.name]: event.target.value });

  
};
const acceptanceChangeHandler = (event) => {
  setEnteredAcceptance(event.target.value);
  setUser({ ...user, [event.target.name]: event.target.value })
  // setUser({ ...user, [event.target.name]: event.target.value });
  // setStudent({ ...student, [event.target.name]: event.target.value });

  
};

const b2examChangeHandler = (event) => {
  setEnteredB2exam(event.target.value);
  setUser({ ...user, [event.target.name]: event.target.value })
  // setUser({ ...user, [event.target.name]: event.target.value });
  // setStudent({ ...student, [event.target.name]: event.target.value });

  
};

const visadateChangeHandler = (event) => {
  setEnteredVisaDate(event.target.value);
  setUser({ ...user, [event.target.name]: event.target.value })
  // setUser({ ...user, [event.target.name]: event.target.value });
  // setStudent({ ...student, [event.target.name]: event.target.value });

  
};

const phoneNumberChangeHandler = (event) => {
  setEnteredPhoneNumber(event.target.value);
  setUser({ ...user, [event.target.name]: event.target.value })
  // setUser({ ...user, [event.target.name]: event.target.value });
  // setStudent({ ...student, [event.target.name]: event.target.value });

  
};


const placementChangeHandler = (event) => {
  setEnteredPlacement(event.target.value);
  setUser({ ...user, [event.target.name]: event.target.value })
  // setUser({ ...user, [event.target.name]: event.target.value });
  // setStudent({ ...student, [event.target.name]: event.target.value });

  
};




const editUserDetails = async (e) => {
  e.preventDefault();
console.log(id);
  const studentData = {
    studentname: studentname,
    coursename: coursename,
    joiningdate: joiningdate,
    cordinationplace:cordinationplace,
    admissionnumber:admissionnumber,
    feepaid: feepaid,
    paybalance: paybalance,
    username: username,
    phonenumber:enteredPhoneNumber?enteredPhoneNumber: phonenumber,
    emailid:enteredEmailId?enteredEmailId: emailid,
    acceptance:enteredAcceptance?enteredAcceptance: acceptance,
    b2exam:enteredB2exam?enteredB2exam: b2exam,
    visadate:enteredVisaDate?enteredVisaDate: visadate,
    company:enteredCompany?enteredCompany: company,
    placement:enteredPlacement?enteredPlacement: placement,
    coursefee:coursefee
  
  };
  // console.log(studentData)
  const response = await editUser(id,studentData);
  history.push("/adminstudentprocess");
};


return (
  <Card className={classes.form}>
    <div className={classes["student-data-form"]}>
      <form>
        <div className={classes["form-control"]}>
        <div
            className={`${classes.control} `}
          >
            <label htmlFor="studentname">Student Name</label>
            <input
              type="text"
              id="studentname"
              value={studentname}
            
              name="studentname"
              disabled
            />
          </div>
          <div
            className={`${classes.control}  `}
          >
            <label htmlFor="phonenumber">Ph. No.</label>
            <input
              type="text"
              id="phonenumber"
              value={phonenumber}
              onChange={phoneNumberChangeHandler}
              
              name="phonenumber"
            />
          </div>
          
          <div
            className={`${classes.control}  `}
          >
            <label htmlFor="emailid">Email ID</label>
            <input
              type="text"
              id="emailid"
              value={emailid}
              onChange={emailidChangeHandler}
              
              name="emailid"
            />
          </div>

         
          <div
            className={`${classes.control} `}
          >
            <label htmlFor="company">Company</label>
            <input
              type="text"
              id="company"
              value={company}
              onChange={companyChangeHandler}
              
              name="company"
            />
          </div>

          <div
            className={`${classes.control} `}
          >
            <label htmlFor="acceptance">Acceptance</label>
            <input
              type="text"
              id="acceptance"
              value={acceptance}
              onChange={acceptanceChangeHandler}
              
              name="acceptance"
            />
          </div>

          <div
            className={`${classes.control} `}
          >
            <label htmlFor="b2exam">Exam Date</label>
            <input
              type="text"
              id="b2exam"
              value={b2exam}
              onChange={b2examChangeHandler}
              
              name="b2exam"
            />
          </div>

          <div
            className={`${classes.control} `}
          >
            <label htmlFor="visadate">VISA Date</label>
            <input
              type="text"
              id="visadate"
              value={visadate}
              onChange={visadateChangeHandler}
              
              name="visadate"
            />
          </div>

          <div
            className={`${classes.control} `}
          >
            <label htmlFor="placement">Status</label>
            <input
              type="text"
              id="placement"
              value={placement}
              onChange={placementChangeHandler}
              
              name="placement"
            />
          </div>

          
          <div className={classes.actions}>
            <Button
              type="submit"
              className={classes.button}
              // disabled={!formIsValid}
              onClick={editUserDetails}
            >
              Edit Student
            </Button>
            <Button
              type="button"
              onClick={() => {
                history.push("/adminstudentprocess");
              }}
            >
              Cancel
            </Button>
          </div>
        </div>
      </form>
    </div>
  </Card>
);
}

export default EditStudentProcess
