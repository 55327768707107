import React, { useEffect, useState } from "react";
import classes from "./StudentData.module.css";
import { getUsers, deleteUser, getUser } from "../../Service/api";
import { Link } from "react-router-dom";
import Switch from "@mui/material/Switch";
import {
  Card,
  FormControlLabel,
  NativeSelect,
  TableContainer,
  TablePagination,
} from "@mui/material";
import { CSVLink } from "react-csv";
import { useContext } from "react";
import { Context } from "../../context/Context";
// import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

// import { styled, useTheme } from '@mui/material/styles';

import {
  Table,
  TableHead,
  TableCell,
  Paper,
  TableRow,
  TableBody,
  Button,
  styled,
  useTheme,
} from "@mui/material";

import AddStudent from "../AddStudent/AddStudent";

const THead = styled(TableRow)`
  & > th {
    font-size: 16px;
    background: #3e426d;
    color: #ffffff;
  }
`;

const StudentData = () => {
  const [course, setCourse] = useState("All");
  const [isEditing, setIsEditing] = useState(false);
  const [checked, setChecked] = useState(false);
  const { user } = useContext(Context);
  const [users, setUsers] = useState([]);
  // const [rows,setRows]=useState(0)
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  // setRows(parseInt(users.length))
  // console.log(users.length);
  const username = user.username;
  // console.log(username);

  let userList = users.filter(function (el) {
    return username === "admin" ? el : el.username === username;
  });
  // console.log(userList);

  const handleChanged = (event) => {
    setChecked(event.target.checked);
  };

  const payBalanceUser = userList.filter(function (el) {
    return !checked ? el : el.paybalance > 0;
  });

  // console.log(checked);

  let downloadUser =
    course === "All"
      ? payBalanceUser.map((e) => e)
      : payBalanceUser
          .filter(function (el) {
            if (course == "All") {
              return el;
            }
            return el.coursename === course;
          })
          .map((e) => e);

  const searched = downloadUser.map((e) => {
    return;
  });

  // console.log(searched);

  const startEditingHandler = () => {
    setIsEditing(true);
  };
  const stopEditingHandler = () => {
    setIsEditing(false);
  };

  const addStudentHandler = (data) => {
    const studentData = {
      ...data,
      id: Math.random().toString(),
    };
    setIsEditing(false);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    console.log(event.target.value);
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    getAllUsers();
  }, []);

  const deleteUserData = async (id) => {
    await deleteUser(id);
    getAllUsers();
  };

  const getAllUsers = async (username) => {
    let response = await getUsers();
    setUsers(response.data);
    // console.log(users);
  };

  const handleChange = async (event) => {
    const coursev = await event.target.value;

    setCourse(coursev);
  };
  return (
    <div>
      <div className={classes.top}>
        {!isEditing && (
          <Card className={classes.addStudent}>
            <div className={classes.studentdataform}>
              <div className={classes.studentTopBar}>
                {user && (
                  <div className={classes.addButton}>
                    {username !== "admin" && (
                      <Button
                        variant="contained"
                        color="primary"
                        sx={{ height: 50 }}
                        onClick={startEditingHandler}
                      >
                        Add New Student
                      </Button>
                    )}
                  </div>
                )}
                {
                  <FormControl
                    variant="standard"
                    sx={{ m: -3, minWidth: 120, maxHeight: -1 }}
                  >
                    <InputLabel id="demo-simple-select-label">
                      Course
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={course}
                      label="Course"
                      onChange={handleChange}
                    >
                      <MenuItem value={"All"}>All</MenuItem>
                      <MenuItem value={"A1"}>A1</MenuItem>
                      <MenuItem value={"B1"}>B1</MenuItem>
                      <MenuItem value={"A2"}>A2</MenuItem>
                      <MenuItem value={"B2"}>B2</MenuItem>
                      <MenuItem value={"Translation Fee"}>
                        Translation Fee
                      </MenuItem>
                      <MenuItem value={"Registration Fee"}>
                        Registration Fee
                      </MenuItem>
                      <MenuItem value={"Others"}>Others</MenuItem>
                    </Select>
                  </FormControl>
                }
                {!isEditing && (
                  <FormControlLabel
                    control={
                      <Switch
                        checked={checked}
                        onChange={handleChanged}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    }
                    label="Fees Due List"
                  />
                )}
                {!isEditing && (
                  <CSVLink className={classes.downloadIcon} data={downloadUser}>
                    <Button
                      variant="contained"
                      color="success"
                      sx={{ height: 50 }}
                    >
                      Download
                    </Button>
                  </CSVLink>
                )}
              </div>
            </div>
          </Card>
        )}
        {isEditing && (
          <AddStudent
            // addStudent={addStudentHandler}
            onCancel={stopEditingHandler}
          />
        )}
      </div>
      {!isEditing && (
        <>
          <Paper
            sx={{
              width: "98%",
              overflow: "hidden",
              marginLeft: 1.2,
              marginTop: 8,
            }}
          >
            <TableContainer sx={{ maxHeight: 500 }}>
              <Table
                sx={{ width: "100%" }}
                stickyHeader
                aria-label="sticky table"
              >
                <TableHead>
                  <THead>
                    <TableCell>Sl. No.</TableCell>
                    <TableCell>Adm. Number</TableCell>
                    <TableCell>Student Name</TableCell>
                    <TableCell>Course Name</TableCell>
                    <TableCell>Level</TableCell>
                    <TableCell>Joining Date</TableCell>
                    
                    <TableCell>Paid</TableCell>
                    <TableCell>Due</TableCell>
                    {/* <TableCell>Comment</TableCell> */}
                    <TableCell></TableCell>
                  </THead>
                </TableHead>
                <TableBody sx={{ height: 20 }}>
                  {downloadUser
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)

                    .map((user, index) => {
                      return (
                        <TableRow
                          key={user._id}
                          sx={{
                            height: 20,
                            backgroundColor:
                              " linear-gradient(90deg, rgba(243,239,246,1) 0%, rgba(210,204,204,1) 50%, rgba(255,242,223,1) 100%)",
                          }}
                        >
                          <TableCell>{index + 1}</TableCell>
                          <TableCell>{user.admissionnumber}</TableCell>
                          <TableCell>{user.studentname}</TableCell>
                          <TableCell>{user.cordinationplace}</TableCell>
                          <TableCell>{user.coursename}</TableCell>
                          <TableCell>
                            {new Date(user.joiningdate).toLocaleDateString(
                              "en-GB"
                            )}
                          </TableCell>
                         
                          <TableCell>Rs. {user.feepaid}</TableCell>
                          <TableCell>Rs. {user.paybalance}</TableCell>
                          {/* <TableCell></TableCell> */}
                          <TableCell>
                            <Button
                              color="primary"
                              variant="contained"
                              style={{ marginRight: 10 }}
                              component={Link}
                              to={`/edit/${user._id}`}
                            >
                              Edit
                            </Button>
                            <Button
                              color="secondary"
                              variant="contained"
                              onClick={() => deleteUserData(user._id)}
                            >
                              Delete
                            </Button>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
          <TablePagination
            className={classes.pagination}
            rowsPerPageOptions={[10, 20, 50]}
            component="div"
            count={users.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </>
      )}
    </div>
  );
};

export default StudentData;
