import { Card, CardContent, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { getUsers } from "../../Service/api";
import classes from "./AdminHome.module.css";
import AdminNavbar from "./AdminNavbar";

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend);

const AdminHome = (props) => {
  const [users, setUsers] = useState([]);
  // const [users, setUsers] = useState([]);
  useEffect(() => {
    getAllUsers();
  }, []);
  const getAllUsers = async () => {
    let response = await getUsers();
    setUsers(response.data);
  };
  const fromDate = "11/01/2023";
  const toDate = "16/01/2023";
  const x2 = users.map((e) => {
    return new Date(e.joiningdate).toLocaleDateString();
  });

  // console.log(x2);
  const x1 = users.map((e) => {
    return e.joiningdate;
  });
 
  const feePaid = users.map((e) => {
    return e.feepaid;
  });
  const totalFeepaid = () => {
    let totalFee = 0;
    for (let i = 0; i < feePaid.length; i += 1) {
      totalFee += feePaid[i];
    }
    return totalFee;
  };
  const fee = totalFeepaid(feePaid);
 

  const payBalance = users.map((e) => {
    return e.paybalance;
  });

  const totalPaybalance = () => {
    let totalBal = 0;
    for (let i = 0; i < payBalance.length; i += 1) {
      totalBal += payBalance[i];
    }
    return totalBal;
  };
  const balance = totalPaybalance(payBalance);


  const totalStudents = users.length;

  const a1 = users.filter((e) => {
    return e.coursename === "A1";
  });
  const a2 = users.filter((e) => {
    return e.coursename === "A2";
  });
  const b1 = users.filter((e) => {
    return e.coursename === "B1";
  });
  const b2 = users.filter((e) => {
    return e.coursename === "B2";
  });
  const translationfee = users.filter((e) => {
    return e.coursename === "Translation Fee";
  });
  const registrationfee = users.filter((e) => {
    return e.coursename === "Registration Fee";
  });
  const others = users.filter((e) => {
    return e.coursename === "Others";
  });
 
  const totalA1 = a1.length;
  const totalA2 = a2.length;
  const totalB1 = b1.length;
  const totalB2 = b2.length;
  const totalTransfee = translationfee.length;
  const totalregifee = registrationfee.length;
  const totalOthers = others.length;


const date = new Date().getMonth()
// console.log(+date+1);
const monthNames = ["January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"
];
let month = monthNames[date];


const data ={
  labels:['A1','A2','B1','B2','Trans Fee','Reg Fee','Others'],
  datasets:[{
    label:'no.',
    data:[totalA1,totalA2,totalB1,totalB2,totalTransfee,totalregifee,totalOthers],
    backgroundColor:['#420e1d','#301E67','#5B8FB9','#B6EADA','#D61355','#FCE22A','#5D3891'],
  }]
}

const data1 ={
  labels:['Total Fee Paid', 'Pay Balance'],
  datasets:[{
    label:'Rs.',
    data:[fee,balance],
    backgroundColor:['black','red'],
  }]
}


const options={}

  return (
    <div className={classes.adminhome_container}>
      <div className={classes.cont}>
        <AdminNavbar />
      </div>
      <div className={classes.dasboard_container}>
        <div>

        
       <div className={classes.allcenter}>

       <div className={classes.cardT}>
            <Card
              sx={{
                // Width: 20,
                background: "rgba(255, 255, 255, 0.15)",
                // height: 240,
                borderRadius:"10px",
                border: "1px solid rgba(255, 255, 255, 0.87)"
              }}
            >
              <CardContent>
                <div className={classes.cardT_heading}>
                  <Typography
                    variant="h5"
                    sx={{ color: "#012849" }}
                    component="div"
                  >
                    Summary Report
                  </Typography>
                </div>
                <div className={classes.cardCont}>
                  <div className={classes.cardleft}>
                    <Typography sx={{ color: "#00325f" }} color="text.secondary">
                      Total Fee Paid :
                    </Typography>
                    <Typography sx={{ color: "#00539b" }} color="text.secondary">
                      Rs. {fee}
                    </Typography>
                  </div>
                  <div>
                    <Typography sx={{ color: "#00325f" }} color="text.secondary">
                      Total Pay Balance :
                    </Typography>
                    <Typography sx={{ color: "#00539b" }} color="text.secondary">
                      Rs.{balance}
                    </Typography>
                  </div>
                  <div>
                    <Typography sx={{ color: "#00325f" }} color="text.secondary">
                      Total Students :
                    </Typography>
                    <Typography sx={{ color: "#00539b" }} color="text.secondary">
                      {totalStudents}
                    </Typography>
                  </div>
                  <div className={classes.cardright}>
                    <div className={classes.studCards}>
                      <div className={classes.cardCourseContainer}>
                        <TableContainer sx={{marginTop:"10px", background: "rgba(255, 255, 255, 0.05)",
                                border: "1px solid rgba(255, 255, 255, 0.78)",
                                boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
                                borderRadius: "16px",marginLeft:"3px"}} component={Paper}>
                          <Table
                            sx={{
                             
                              background: "rgba(132, 166, 219, 0.65)",
                                border: "1px solid rgba(255, 255, 255, 0.78)",
                                boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
                                borderRadius: "16px",
                                
                              }}
                            aria-label="simple table"
                          >
                            <TableHead>
                              <TableRow>
                                <TableCell align="left">A1</TableCell>
                                <TableCell align="left">A2</TableCell>
                                <TableCell align="left">B1</TableCell>
                                <TableCell align="left">B2</TableCell>
                                <TableCell align="left">Translation</TableCell>
                                <TableCell align="left">Registration</TableCell>
                                <TableCell align="left">Others</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              <TableRow
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell align="left">{totalA1}</TableCell>
                                <TableCell align="left">{totalA2}</TableCell>
                                <TableCell align="left">{totalB1}</TableCell>
                                <TableCell align="left">{totalB2}</TableCell>
                                <TableCell align="left">
                                  {totalTransfee}
                                </TableCell>
                                <TableCell align="left">
                                  {totalregifee}
                                </TableCell>
                                <TableCell align="left">
                                  {totalOthers}
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </TableContainer>

                        
                      </div>
                    </div>
                  </div>
                </div>
              </CardContent>
            </Card>
          </div>
       </div>

       <div className={classes.doughnut}>
        <div className={classes.dough2}>

        <Doughnut data={data} options={options}></Doughnut>
        </div>
        <div className={classes.dough1}>

        <Doughnut data={data1} options={options}></Doughnut>
        </div>
       </div>
       </div>
      </div>
    </div>
  );
};

export default AdminHome;
