// const axios = require('axios')
import axios from "axios";
const usersUrl = "https://flugeleducare-server1.onrender.com";

// export const loginUser = async (user) => {
//   return await axios.post(`${usersUrl}/login`, user);
// };

export const getUsers = async (id) => {
  id = id || "";
  return await axios.get(`${usersUrl}/${id}`);
};

export const getadminusers = async () => {
  return await axios.get(`${usersUrl}/adminuserslist`);
};
export const getadminuser = async (id) => {
  // id = id || "";
  var id = await id;

  return await axios.get(`${usersUrl}/editadminuser/${id}`);
};

export const editUserlist = async (id, user) => {
  // console.log(user);
  return await axios.put(`${usersUrl}/edituser/${id}`, user);
};

export const addUser = async (user) => {
  // console.log(user);
  return await axios.post(`${usersUrl}/add`, user);
};
// export const adminAddUser = async (user)=>{
// return await axios.post(`${usersUrl}`)
// }

export const addUserLogin = async (user) => {
  return await axios.post(`${usersUrl}/adduser`, user);
};

export const deleteUser = async (id) => {
  return await axios.delete(`${usersUrl}/${id}`);
};

export const editUser = async (id, user) => {
  console.log(user);
  return await axios.put(`${usersUrl}/${id}`, user);
};

export const getMessages = async () => {
  return await axios.get(`${usersUrl}/messageS`);
};

export const deleteMessage = async (id) => {
  return await axios.delete(`${usersUrl}/messageS/${id}`);
};

export const messageSent = async (message) => {
  // console.log(message);
  return await axios.post(`${usersUrl}/messageS`, message);
};
