import React, { useState } from "react";
// import Card from "../UI/Card/Card";
import Button from "../UI/Button/Button";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
// import classes from "./AddStudent.Module.css";
import classes from "./AddStudent.module.css";
import { addUser } from "../../Service/api";
import { useContext } from "react";
import { Context } from "../../context/Context";
import Card from "../UI/Card/Card";
import { useHistory } from "react-router-dom";
var randomstring = require("randomstring");
var randomize = require('randomatic');

const defaultValue = {
  admissionnumber: "",
  studentname: "",
  coursename: "",
  coursefee: "",
  joiningdate: "",
  cordinationplace: "",
  feepaid: "",
};

const AddStudent = (props) => {
  const [enteredUserName, setEnteredUserName] = useState("");
  const [userNameIsValid, setUserNameIsValid] = useState();
  const [enteredCourseName, setEnteredCourseName] = useState("");
  const [courseNameIsValid, setCourseNameIsValid] = useState();
  const [enteredCourseFee, setEnteredCourseFee] = useState("");
  const [courseFeeIsValid, setCourseFeeIsValid] = useState();
  const [enteredJoiningDate, setEnteredJoiningDate] = useState("");
  const [joiningDateIsValid, setJoiningDateIsValid] = useState();
  const [enteredCordinationPlace, setEnteredCordinationPlace] = useState("");
  const [cordinationPlaceIsValid, setCordinationPlaceIsValid] = useState();
  const [enteredAdmissionNumber, setEnteredAdmissionNumber] = useState("");
  const [admissionNumberIsValid, setAdmissionNumberIsValid] = useState();
  const [enteredFeePaid, setEnteredFeePaid] = useState("");
  const [feePaidIsValid, setFeePaidIsValid] = useState();
  // const [studentData, setStudentData] = useState([]);
  const [formIsValid, setFormIsValid] = useState(false);
  const [student, setStudent] = useState(defaultValue);
  const { dispatch, user } = useContext(Context);
  const adname = user.username??'FLUGEL';
  let userName=(adname.substr(0,3).toUpperCase());
  let history = useHistory();

  const userNameChangeHandler = (event) => {
    setEnteredUserName(event.target.value);
    setStudent({ ...student, [event.target.name]: event.target.value });

    setFormIsValid(
      event.target.value.length > 0 &&
        enteredCourseName.trim().length > 0 &&
        enteredJoiningDate.length > 0 &&
        enteredCordinationPlace.trim().length > 0 &&
        enteredAdmissionNumber.trim().length > 0 &&
        enteredCourseFee.length > 0 &&
        enteredFeePaid.length > 0
    );
  };

  const courseNameChangeHandler = (event) => {
    setEnteredCourseName(event.target.value);
    setStudent({ ...student, [event.target.name]: event.target.value });

    setFormIsValid(
      event.target.value.length > 0 &&
        enteredUserName.trim().length > 0 &&
        enteredJoiningDate.length > 0 &&
        enteredCordinationPlace.trim().length > 0 &&
        enteredAdmissionNumber.trim().length > 0 &&
        enteredCourseFee.length > 0 &&
        enteredFeePaid.length > 0
    );
  };
  const courseFeeChangeHandler = (event) => {
    setEnteredCourseFee(event.target.value);
    // console.log(+enteredCourseFee - +enteredFeePaid);
    setStudent({ ...student, [event.target.name]: event.target.value });

    setFormIsValid(
      event.target.value.length > 0 &&
        enteredUserName.trim().length > 0 &&
        enteredJoiningDate.length > 0 &&
        enteredCordinationPlace.trim().length > 0 &&
        enteredAdmissionNumber.trim().length > 0 &&
        enteredCourseName.length > 0 &&
        enteredFeePaid.length > 0
    );
  };

  const joiningDateChangeHandler = (event) => {
    setEnteredJoiningDate(event.target.value);
    setStudent({ ...student, [event.target.name]: event.target.value });

    setFormIsValid(
      event.target.value.length > 0 &&
        enteredUserName.trim().length > 0 &&
        enteredCourseName.length > 0 &&
        enteredCordinationPlace.trim().length > 0 &&
        enteredAdmissionNumber.trim().length > 0 &&
        enteredCourseFee.length > 0 &&
        enteredFeePaid.length > 0
    );
  };

  const cordinationPlaceChangeHandler = (event) => {
    setEnteredCordinationPlace(event.target.value);
    setStudent({ ...student, [event.target.name]: event.target.value });

    setFormIsValid(
      event.target.value.length > 0 &&
        enteredUserName.trim().length > 0 &&
        enteredJoiningDate.length > 0 &&
        enteredCourseName.trim().length > 0 &&
        enteredAdmissionNumber.trim().length > 0 &&
        enteredCourseFee.length > 0 &&
        enteredFeePaid.length > 0
    );
  };

  const admissionNumberChangeHandler = (event) => {
    setEnteredAdmissionNumber(event.target.value);
    setStudent({ ...student, [event.target.name]: event.target.value });

    setFormIsValid(
      event.target.value.length > 0 &&
        enteredUserName.trim().length > 0 &&
        enteredJoiningDate.length > 0 &&
        enteredCordinationPlace.trim().length > 0 &&
        enteredCourseName.trim().length > 0 &&
        enteredCourseFee.length > 0 &&
        enteredFeePaid.length > 0
    );
  };

  const feePaidChangeHandler = (event) => {
    setEnteredFeePaid(event.target.value);
    setStudent({ ...student, [event.target.name]: event.target.value });

    setFormIsValid(
      event.target.value.length > 0 &&
        enteredUserName.trim().length > 0 &&
        enteredJoiningDate.length > 0 &&
        enteredCordinationPlace.trim().length > 0 &&
        enteredCourseName.trim().length > 0 &&
        enteredCourseFee.length > 0
    );
  };

  const validateUserNameHandler = () => {
    setUserNameIsValid(enteredUserName.length > 0);
  };

  const validateCourseNameHandler = () => {
    setCourseNameIsValid(enteredCourseName.length > 0);
  };
  const validateCourseFeeHandler = () => {
    setCourseFeeIsValid(enteredCourseFee.length > 0);
  };

  const validateJoiningDateHandler = () => {
    setJoiningDateIsValid(enteredJoiningDate.length > 0);
  };

  const validateCordinationPlaceHandler = () => {
    setCordinationPlaceIsValid(enteredCordinationPlace.length > 0);
  };

  const validateAdmissionNumberHandler = () => {
    setAdmissionNumberIsValid(enteredAdmissionNumber.length > 0);
  };
  const validateFeePaidHandler = () => {
    setFeePaidIsValid(enteredFeePaid.length > 0);
  };
  const options = [
    "A1",
    "A2",
    "B1",
    "B2",
    "Translation fees",
    "Registration Fee",
    "Others",
  ];
  const submitHandler = async (event) => {
    event.preventDefault();
    // console.log(event.target.value);
    const courseFee = parseInt(enteredCourseFee);
    const feePaid = parseInt(enteredFeePaid);

    const dueAmount = courseFee - feePaid;

    const studentData = {
      studentname: enteredUserName,
      coursename: enteredCourseName,
      coursefee: enteredCourseFee,
      joiningdate: new Date(enteredJoiningDate),
      cordinationplace: enteredCordinationPlace,
      admissionnumber: enteredAdmissionNumber,
      feepaid: enteredFeePaid,
      paybalance: dueAmount,
      username: user.username,
      phonenumber: "",
      emailid: "",
      company: "",
      acceptance: "",
      b2exam: "",
      visadate: "",
      placement: "",
    };

    await addUser(studentData);
    window.location.replace("/");
    setEnteredUserName("");
    setEnteredCourseName("");
    setEnteredJoiningDate("");
    setEnteredCordinationPlace("");
    setEnteredAdmissionNumber("");
    setEnteredFeePaid("");
    setEnteredCourseFee("");
  };
  return (
    <Card className={classes.form}>
      <div className={classes["student-data-form"]}>
        <form onSubmit={submitHandler}>
          <div className={classes["form-control"]}>
            <div
              className={`${classes.control} ${
                admissionNumberIsValid === false ? classes.invalid : ""
              } `}
            >
              <label htmlFor="admissionNumber">Admission Number</label>
              <input
                type="text"
                id="admissionNumber"
                // onMouseEnter={()=>setEnteredAdmissionNumber(randomstring.generate(10).toUpperCase())}
                // onMouseEnter={()=>setEnteredAdmissionNumber(userName+'-'+randomize('0',7))}
                value={enteredAdmissionNumber}
                onChange={admissionNumberChangeHandler}
                onBlur={validateAdmissionNumberHandler}
                name="admissionnumber"
              />
            </div>
            <div
              className={`${classes.control} ${
                userNameIsValid === false ? classes.invalid : ""
              } `}
            >
              <label htmlFor="userName">Student name</label>
              <input
                type="text"
                id="userName"
                value={enteredUserName}
                onChange={userNameChangeHandler}
                onBlur={validateUserNameHandler}
                name="studentname"
              />
            </div>
            <div
              className={`${classes.control} ${
                cordinationPlaceIsValid === false ? classes.invalid : ""
              } `}
            >
              <label htmlFor="cordinationPlace">Course Name</label>
              <input
                type="text"
                id="cordinatorName"
                value={enteredCordinationPlace}
                onChange={cordinationPlaceChangeHandler}
                onBlur={validateCordinationPlaceHandler}
                name="cordinationplace"
              />
            </div>
            <div
              className={`${classes.control} ${
                courseNameIsValid === false ? classes.invalid : ""
              } ${classes.formControlContainer}`}
            >
              <label htmlFor="courseName">Level</label>

              <div className={classes.formControl}>
                <FormControl variant="standard" sx={{ width: 510 }}>
                  <Select
                    labelId="demo-simple-select-label"
                    id="courseName"
                    value={enteredCourseName}
                    onChange={courseNameChangeHandler}
                  >
                    <MenuItem value={"A1"}>A1</MenuItem>
                    <MenuItem value={"A2"}>A2</MenuItem>
                    <MenuItem value={"B1"}>B1</MenuItem>
                    <MenuItem value={"B2"}>B2</MenuItem>
                    <MenuItem value={"Translation Fee"}>
                      Translation Fee
                    </MenuItem>
                    <MenuItem value={"Registration Fee"}>
                      Registration Fee
                    </MenuItem>
                    <MenuItem value={"Others"}>Others</MenuItem>
                  </Select>
                </FormControl>
              </div>
            </div>
            <div
              className={`${classes.control} ${
                courseFeeIsValid === false ? classes.invalid : ""
              } `}
            >
              <label htmlFor="courseFee">Course Fee Rs.</label>
              <input
                type="number"
                id="courseFee"
                value={enteredCourseFee}
                onChange={courseFeeChangeHandler}
                onBlur={validateCourseFeeHandler}
                name="coursefee"
              />
            </div>
            <div
              className={`${classes.control} ${
                joiningDateIsValid === false ? classes.invalid : ""
              } `}
            >
              <label htmlFor="joiningDate">Joining Date</label>
              <input
                type="date"
                id="joiningDate"
                value={enteredJoiningDate}
                onChange={joiningDateChangeHandler}
                onBlur={validateJoiningDateHandler}
                name="joiningdate"
              />
            </div>
            

            <div
              className={`${classes.control} ${
                feePaidIsValid === false ? classes.invalid : ""
              } `}
            >
              <label htmlFor="feePaid">Fee Paid Rs.</label>
              <input
                type="number"
                id="feePaid"
                value={enteredFeePaid}
                onChange={feePaidChangeHandler}
                onBlur={validateFeePaidHandler}
                name="feepaid"
              />
            </div>

            <div className={classes.actions}>
              <Button
                type="submit"
                className={classes.button}
                disabled={!formIsValid}
              >
                Add Student
              </Button>
              <Button type="button" onClick={props.onCancel}>
                Cancel
              </Button>
            </div>
          </div>
        </form>
      </div>
    </Card>
  );
};

export default AddStudent;
