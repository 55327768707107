import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { getUsers, editUser } from "../../Service/api";
import Button from "../UI/Button/Button";
import classes from "./EditStudent.module.css";
import Card from "../UI/Card/Card";

const defaultValue = {
  admissionnumber: "",
  studentname: "",
  coursename: "",
  coursefee: "",
  joiningdate: "",
  cordinationplace: "",
  feepaid: "",
  paybalance: "",
};
const initialvalue = {
  admissionnumber: "",
  studentname: "",
  coursename: "",
  coursefee: "",
  joiningdate: "",
  cordinationplace: "",
  feepaid: "",
  paybalance: "",
};
const EditStudent = (props) => {
  const [enteredUserName, setEnteredUserName] = useState("");
  const [userNameIsValid, setUserNameIsValid] = useState();
  const [enteredCourseName, setEnteredCourseName] = useState("");
  const [courseNameIsValid, setCourseNameIsValid] = useState();
  const [enteredJoiningDate, setEnteredJoiningDate] = useState("");
  const [joiningDateIsValid, setJoiningDateIsValid] = useState();
  const [enteredCordinationPlace, setEnteredCordinationPlace] = useState("");
  const [cordinationPlaceIsValid, setCordinationPlaceIsValid] = useState();
  const [enteredAdmissionNumber, setEnteredAdmissionNumber] = useState("");
  const [admissionNumberIsValid, setAdmissionNumberIsValid] = useState();
  const [enteredFeePaid, setEnteredFeePaid] = useState("");
  const [feePaidIsValid, setFeePaidIsValid] = useState();
  const [paybal, setPaybal] = useState("");
  const [feepay, setFeepay] = useState("");
  const [actualBalance, setActualBalance] = useState(0);
  const [actualFeePaid, setActualFeepaid] = useState(0);

  const [formIsValid, setFormIsValid] = useState(false);

  const [user, setUser] = useState(defaultValue);
  const [editUser1, setEditUser1] = useState(initialvalue);

  const {
    admissionnumber,
    studentname,
    coursename,
    joiningdate,
    cordinationplace,
    feepaid,
    paybalance,
    phonenumber,
    emailid,
    company,
    placement,
    acceptance,
    b2exam,
    visadate

  } = user;
  const { id } = useParams();

  let history = useHistory();

  useEffect(() => {
    loadUserDetails();
    // setPaybal(user.paybalance)
  }, []);

  const loadUserDetails = async () => {
    const response = await getUsers(id);
    console.log(response);
    setUser(response.data);
  };

  const userNameChangeHandler = (event) => {
    setEnteredUserName(event.target.value);
    setUser({ ...user, [event.target.name]: event.target.value });

    setFormIsValid(
      event.target.value.length > 0 &&
        enteredCourseName.trim().length > 0 &&
        enteredJoiningDate.length > 0 &&
        enteredCordinationPlace.trim().length > 0 &&
        enteredAdmissionNumber.trim().length > 0 &&
        enteredFeePaid.length > 0
    );
  };

  const courseNameChangeHandler = (event) => {
    setEnteredCourseName(event.target.value);
    setUser({ ...user, [event.target.name]: event.target.value });
    // setStudent({ ...student, [event.target.name]: event.target.value });

    setFormIsValid(
      event.target.value.length > 0 &&
        enteredUserName.trim().length > 0 &&
        enteredJoiningDate.length > 0 &&
        enteredCordinationPlace.trim().length > 0 &&
        enteredAdmissionNumber.trim().length > 0 &&
        enteredFeePaid.length > 0
    );
  };

  const joiningDateChangeHandler = (event) => {
    setEnteredJoiningDate(event.target.value);
    setUser({ ...user, [event.target.name]: event.target.value });
    // setStudent({ ...student, [event.target.name]: event.target.value });

    setFormIsValid(
      event.target.value.length > 0 &&
        enteredUserName.trim().length > 0 &&
        enteredCourseName.length > 0 &&
        enteredCordinationPlace.trim().length > 0 &&
        enteredAdmissionNumber.trim().length > 0 &&
        enteredFeePaid.length > 0
    );
  };
  const cordinationPlaceChangeHandler = (event) => {
    setEnteredCordinationPlace(event.target.value);
    setUser({ ...user, [event.target.name]: event.target.value });
    // setStudent({ ...student, [event.target.name]: event.target.value });

    setFormIsValid(
      event.target.value.length > 0 &&
        enteredUserName.trim().length > 0 &&
        enteredJoiningDate.length > 0 &&
        enteredCourseName.trim().length > 0 &&
        enteredAdmissionNumber.trim().length > 0 &&
        enteredFeePaid.length > 0
    );
  };

  const admissionNumberChangeHandler = (event) => {
    setEnteredAdmissionNumber(event.target.value);
    setUser({ ...user, [event.target.name]: event.target.value });
    // setStudent({ ...student, [event.target.name]: event.target.value });

    setFormIsValid(
      event.target.value.length > 0 &&
        enteredUserName.trim().length > 0 &&
        enteredJoiningDate.length > 0 &&
        enteredCordinationPlace.trim().length > 0 &&
        enteredCourseName.trim().length > 0 &&
        enteredFeePaid.length > 0
    );
  };

  const feePaidChangeHandler = (event) => {
    setEnteredFeePaid(event.target.value);
    // setUser({...user,[event.target.name]: (+event.target.value) })
    const paybalance = +paybal - parseInt(event.target.value);
    const feePaid = +feepay + parseInt(event.target.value);
    setActualBalance(paybalance);
    setActualFeepaid(feePaid);

    setFormIsValid(
      event.target.value.length > 0 &&
        enteredUserName.trim().length > 0 &&
        enteredJoiningDate.length > 0 &&
        enteredCordinationPlace.trim().length > 0 &&
        enteredCourseName.trim().length > 0
    );
  };
  useEffect(() => {}, [actualBalance, actualFeePaid]);
  const validateUserNameHandler = () => {
    setUserNameIsValid(enteredUserName.length > 0);
  };

  const validateCourseNameHandler = () => {
    setCourseNameIsValid(enteredCourseName.length > 0);
  };

  const validateJoiningDateHandler = () => {
    setJoiningDateIsValid(enteredJoiningDate.length > 0);
  };

  const validateCordinationPlaceHandler = () => {
    setCordinationPlaceIsValid(enteredCordinationPlace.length > 0);
  };

  const validateAdmissionNumberHandler = () => {
    setAdmissionNumberIsValid(enteredAdmissionNumber.length > 0);
  };
  const validateFeePaidHandler = () => {
    setFeePaidIsValid(enteredFeePaid.length > 0);
  };

  const editUserDetails = async (e) => {
    e.preventDefault();

    const studentData = {
      studentname: enteredUserName?enteredUserName:studentname,
      coursename: enteredCourseName?enteredCourseName:coursename,
      joiningdate: joiningdate,
      cordinationplace: enteredCordinationPlace?enteredCordinationPlace:cordinationplace,
      admissionnumber: enteredAdmissionNumber?enteredAdmissionNumber:admissionnumber,
      feepaid: actualFeePaid,
      paybalance: actualBalance,
      username: user.username,
      phonenumber:phonenumber,
      emailid:emailid,
      acceptance:acceptance,
      b2exam:b2exam,
      visadate:visadate,
      company:company,
      placement:placement,
    
    };
    const response = await editUser(id,studentData);
    history.push("/");
  };
  useEffect(() => {
    const balance = user.paybalance;
    const paid = user.feepaid;
    // console.log(paid);
    setPaybal(balance);
    setFeepay(paid);
  }, [user]);

  return (
    <Card className={classes.form}>
      <div className={classes["student-data-form"]}>
        <form>
          <div className={classes["form-control"]}>
            <div
              className={`${classes.control} ${
                admissionNumberIsValid === false ? classes.invalid : ""
              } `}
            >
              <label htmlFor="admissionNumber">Admission Number</label>
              <input
                type="text"
                id="admissionNumber"
                value={admissionnumber}
                onChange={admissionNumberChangeHandler}
                onBlur={validateAdmissionNumberHandler}
                name="admissionnumber"
              />
            </div>
            <div
              className={`${classes.control} ${
                userNameIsValid === false ? classes.invalid : ""
              } `}
            >
              <label htmlFor="userName">Student name</label>
              <input
                type="text"
                id="userName"
                value={studentname}
                onChange={userNameChangeHandler}
                onBlur={validateUserNameHandler}
                name="studentname"
              />
            </div>
            <div
              className={`${classes.control} ${
                courseNameIsValid === false ? classes.invalid : ""
              } `}
            >
              <label htmlFor="courseName">Course name</label>
              <input
                type="text"
                id="courseName"
                value={coursename}
                onChange={courseNameChangeHandler}
                onBlur={validateCourseNameHandler}
                name="coursename"
              />
            </div>

           
            <div
              className={`${classes.control} ${
                cordinationPlaceIsValid === false ? classes.invalid : ""
              } `}
            >
              <label htmlFor="cordinationPlace">Cordination Place</label>
              <input
                type="text"
                id="cordinationPlace"
                value={cordinationplace}
                onChange={cordinationPlaceChangeHandler}
                onBlur={validateCordinationPlaceHandler}
                name="cordinationplace"
              />
            </div>

            <div
              className={`${classes.control} ${
                feePaidIsValid === false ? classes.invalid : ""
              } `}
            >
              <label htmlFor="feePaid">Fee Paid Rs.</label>
              <input
                type="number"
                id="feePaid"
                // value={}
                onChange={feePaidChangeHandler}
                onBlur={validateFeePaidHandler}
                name="feepaid"
              />
            </div>

            <div className={classes.actions}>
              <Button
                type="submit"
                className={classes.button}
                // disabled={!formIsValid}
                onClick={editUserDetails}
              >
                Edit Student
              </Button>
              <Button
                type="button"
                onClick={() => {
                  history.push("/");
                }}
              >
                Cancel
              </Button>
            </div>
          </div>
        </form>
      </div>
    </Card>
  );
};

export default EditStudent;
