import {
  Button,
  Card,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  styled,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import { Context } from "../../context/Context";
import { getUsers } from "../../Service/api";
import { Link } from "react-router-dom";
import classes from "./StudentProcess.module.css";

const THead = styled(TableRow)`
  & > th {
    font-size: 16px;
    background: #3e426d;
    color: #ffffff;
  }
`;

const StudentProcess = () => {
  const [users, setUsers] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [course, setCourse] = useState("All");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [checked, setChecked] = useState(false);
  const { user } = useContext(Context);
  const username = user.username;
  let userList = users.filter(function (el) {
    return username === "admin"||"manager" ? el : el.username === username;
  });
  // console.log(userList);

  const handleChanged = (event) => {
    setChecked(event.target.checked);
  };

  const payBalanceUser = userList.filter(function (el) {
    return !checked ? el : el.paybalance > 0;
  });

  // console.log(checked);

  let downloadUser =
    course === "All"
      ? payBalanceUser.map((e) => e)
      : payBalanceUser
          .filter(function (el) {
            if (course == "All") {
              return el;
            }
            return el.coursename === course;
          })
          .map((e) => e);

  const searched = downloadUser.map((e) => {
    return;
  });

  // console.log(searched);

//   const startEditingHandler = () => {
//     setIsEditing(true);
//   };
//   const stopEditingHandler = () => {
//     setIsEditing(false);
//   };
  const getAllUsers = async (username) => {
    let response = await getUsers();
    setUsers(response.data);
    // console.log(users);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    console.log(event.target.value);
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  useEffect(() => {
    getAllUsers();
  }, []);

  const handleChange = async (event) => {
    const coursev = await event.target.value;

    setCourse(coursev);
  };
  return (
    <div>
      <div className={classes.top}>
        { (
          <Card className={classes.addStudent}>
            <div className={classes.studentdataform}>
              <div className={classes.studentTopBar}>
                {
                  <FormControl
                    variant="standard"
                    sx={{ m: -3, minWidth: 120, maxHeight: -1 }}
                  >
                    <InputLabel id="demo-simple-select-label">
                      Course
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={course}
                      label="Course"
                      onChange={handleChange}
                    >
                      <MenuItem value={"All"}>All</MenuItem>
                      <MenuItem value={"A1"}>A1</MenuItem>
                      <MenuItem value={"B1"}>B1</MenuItem>
                      <MenuItem value={"A2"}>A2</MenuItem>
                      <MenuItem value={"B2"}>B2</MenuItem>
                      <MenuItem value={"Translation Fee"}>
                        Translation Fee
                      </MenuItem>
                      <MenuItem value={"Registration Fee"}>
                        Registration Fee
                      </MenuItem>
                      <MenuItem value={"Others"}>Others</MenuItem>
                    </Select>
                  </FormControl>
                }

                { (
                  <CSVLink className={classes.downloadIcon} data={downloadUser}>
                    <Button
                      variant="contained"
                      color="success"
                      sx={{ height: 50 }}
                    >
                      Download
                    </Button>
                  </CSVLink>
                )}
              </div>
            </div>
          </Card>
        )}

        {(
          <>
            <Paper
              sx={{
                width: "98%",
                overflow: "hidden",
                marginLeft: 1.2,
                marginTop: 8,
              }}
            >
              <TableContainer sx={{ maxHeight: 500 }}>
                <Table
                  sx={{ width: "100%" }}
                  stickyHeader
                  aria-label="sticky table"
                >
                  <TableHead>
                    <THead>
                      <TableCell>Adm. No.</TableCell>
                      <TableCell>Name</TableCell>
                      <TableCell>Email ID</TableCell>
                      <TableCell>Ph. No.</TableCell>
                      <TableCell>Level</TableCell>
                      <TableCell>Company</TableCell>
                      <TableCell>Acceptance</TableCell>
                      <TableCell>Exam Date</TableCell>
                      <TableCell>VISA Date</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell></TableCell>
                    </THead>
                  </TableHead>
                  <TableBody sx={{ height: 20 }}>
                    {downloadUser
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )

                      .map((user, index) => {
                        return (
                          <TableRow
                            key={user._id}
                            sx={{
                              height: 20,
                              backgroundColor:
                                " linear-gradient(90deg, rgba(243,239,246,1) 0%, rgba(210,204,204,1) 50%, rgba(255,242,223,1) 100%)",
                            }}
                          >
                            <TableCell>{user.admissionnumber}</TableCell>
                            <TableCell>{user.studentname}</TableCell>
                            <TableCell>{user.emailid}</TableCell>
                            <TableCell>{user.phonenumber}</TableCell>
                            <TableCell>{user.coursename}</TableCell>
                            <TableCell>{user.company}</TableCell>
                            <TableCell>{user.acceptance}</TableCell>
                            <TableCell>{user.b2exam}</TableCell>
                            <TableCell>{user.visadate}</TableCell>
                            <TableCell>{user.placement}</TableCell>
                            <TableCell>
                              <Button
                                color="primary"
                                variant="contained"
                                style={{ marginRight: 10 }}
                                component={Link}
                                to={`/editprocess/${user._id}`}
                              >
                                Edit
                              </Button>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
            <TablePagination
              className={classes.pagination}
              rowsPerPageOptions={[10, 20, 50]}
              component="div"
              count={users.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default StudentProcess;
