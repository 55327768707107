import React from 'react'
import classes from './Footer.module.css'
const Footer = () => {
  return (
    <div className={classes.footer_container}>
        <div className={classes.footer_item}>
            <p>&copy; Copyright 2023 flugel educare</p>

        </div>
    </div>
  )
}

export default Footer;