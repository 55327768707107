import React, { useEffect, useState } from "react";
import AddStudent from "../AddStudent/AddStudent";
import EditStudent from "../EditStudent/EditStudent";
import Login from "../Login/Login";
import StudentData from "../StudentData/StudentData";

import classes from "./Home.module.css";
import { useContext } from "react";
import { Context } from "../../context/Context";

const Home = (props) => {
  const [isEditing, setIsEditing] = useState(false);
  const { user } = useContext(Context);
  const [login, setLogin] = useState(false);
  const [username,setUsername]=useState('')
  // const [isUpdating,setIsUpdating] = useState(false);
  const [studentRecData, setStudentRecData] = useState([]);
  // useEffect(()=>{
  // console.log(studentData);
  // },[])
// console.log(user);
 
    // setStudentRecData((prevData) => {
    //   return [studentData, ...prevData];
    // });



  const loginHandler = (props)=>{
    setLogin(props)
  }
  const usernameChangehandler = (data)=>{
    // console.log(data);
    props.onLoginHand(data)
   

  }
// !user&&setLogin(false)

  return (
    <div className={classes.home}>
      {!user && <Login onLogin={loginHandler} onLoginUsername={usernameChangehandler}/>}
      

      {user&&<StudentData />}
    </div>
  );

  }
export default Home;
