import { Button, TextField } from "@mui/material";
import classes from "./Compose.module.css";
import { useHistory } from "react-router-dom";
import { useState } from "react";
import { messageSent } from "../../Service/api";

const Compose = () => {
  const history = useHistory();
  const [messageData, setMessageData] = useState({
    name: "",
    message: "",
  });
  const [name,setName] = useState('');
  const [message,setMessage] = useState('');

  const cancelChangeHandler = () => {
    history.push("/");
  };
  const namechangehandler = (e) => {
    setMessageData({ ...messageData, [e.target.name]: e.target.value });
    setName(e.target.value);
  };

  const messagechangehandler = (e) => {
    setMessageData({ ...messageData, [e.target.name]: e.target.value });
    setMessage(e.target.value);
  };
  const sendMessageHandler = async (e) => {
    e.preventDefault();
    const messageD={
        name:name,
        message:message
    }
    const res = await messageSent(messageD);
    history.push("/");
  };
  return (
    <div className={classes.compose_container}>
      <div className={classes.compose_form_container}>
        <form className={classes.compose_form}>
          <div className={classes.compose_form_item}>
            <TextField
              required
              id="standard-required"
              label="Staff Name"
              name="name"
              //   defaultValue="Type here..."
              onChange={namechangehandler}
              variant="standard"
            />
          </div>
          <div className={classes.compose_form_item}>
            <TextField
              required
              className={classes.compose_form_item}
              id="standard-multiline-static"
              label="Message"
              multiline
              rows={3}
              name="message"
              onChange={messagechangehandler}
              //   defaultValue="Type here..."
              variant="standard"
            />
          </div>
          <div className={classes.compose_form_action}>
            <div className={classes.compose_form_action_button}>
              <Button variant="outlined" onClick={cancelChangeHandler}>
                Cancel
              </Button>
            </div>
            <div className={classes.compose_form_action_button}>
              <Button onClick={sendMessageHandler} variant="contained">
                Send message
              </Button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Compose;
