import AdminNavbar from './AdminNavbar';
import classes from './AdminStudentProcess.module.css'
import StudentProcess from './StudentProcess';

const AdminStudentProcess = () => {
   

  return (
    <div className={classes.adminstudent}>
        <div >
        <AdminNavbar />
      </div>
      <div className={ classes.adminstudent__container}>
        <StudentProcess />
      </div>

    </div>
  )
}

export default AdminStudentProcess