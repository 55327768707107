import React from "react";
import StudentData from "../StudentData/StudentData";
import AdminNavbar from "./AdminNavbar";
import classes from "./AdminStudent.module.css";

const AdminStudent = () => {
  return (
    <div className={classes.adminstudent}>
      <div >
        <AdminNavbar />
      </div>
      <div className={classes.adminstudent__container}>
        <StudentData />
      </div>
    </div>
  );
};

export default AdminStudent;
