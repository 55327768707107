import React, { useEffect, useState } from "react";
import AdminCard from "./AdminCard";
import { getUsers } from "../../Service/api";
import classes from "./AdminCenters.module.css";
import AdminNavbar from "./AdminNavbar";
import { Button } from "@mui/material";

const AdminCenters = () => {
  const [users, setUsers] = useState([]);
  useEffect(() => {
    getAllUsers();
  }, []);
  const getAllUsers = async () => {
    let response = await getUsers();
    setUsers(response.data);
  };
  const fromDate = "11/01/2023";
  const toDate = "16/01/2023";
  const x2 = users.map((e) => {
    return new Date(e.joiningdate).toLocaleDateString();
  });

  const x1 = users.map((e) => {
    return e.joiningdate;
  });

  const feePaid = users.map((e) => {
    return e.feepaid;
  });
  const totalFeepaid = () => {
    let totalFee = 0;
    for (let i = 0; i < feePaid.length; i += 1) {
      totalFee += feePaid[i];
    }
    return totalFee;
  };
  const fee = totalFeepaid(feePaid);

  const payBalance = users.map((e) => {
    return e.paybalance;
  });

  const totalPaybalance = () => {
    let totalBal = 0;
    for (let i = 0; i < payBalance.length; i += 1) {
      totalBal += payBalance[i];
    }
    return totalBal;
  };
  const balance = totalPaybalance(payBalance);

  const totalStudents = users.length;

  const a1 = users.filter((e) => {
    return e.coursename === "A1";
  });
  const a2 = users.filter((e) => {
    return e.coursename === "A2";
  });
  const b1 = users.filter((e) => {
    return e.coursename === "B1";
  });
  const b2 = users.filter((e) => {
    return e.coursename === "B2";
  });
  const translationfee = users.filter((e) => {
    return e.coursename === "Translation Fee";
  });
  const registrationfee = users.filter((e) => {
    return e.coursename === "Registration Fee";
  });
  const others = users.filter((e) => {
    return e.coursename === "Others";
  });

  const totalA1 = a1.length;
  const totalA2 = a2.length;
  const totalB1 = b1.length;
  const totalB2 = b2.length;
  const totalTransfee = translationfee.length;
  const totalregifee = registrationfee.length;
  const totalOthers = others.length;

  // Thrissur paybalance
  const thrissur = users.filter((e) => {
    return e.username === "Thrissur";
  });
  const tcrpay = thrissur.map((e) => {
    return e.paybalance;
  });
  const tcrfee = thrissur.map((e) => {
    return e.feepaid;
  });
  const tcrfinalpay = () => {
    let totalBal = 0;
    for (let i = 0; i < tcrpay.length; i += 1) {
      totalBal += tcrpay[i];
    }
    return totalBal;
  };
  const tcrfinalfee = () => {
    let totalBal = 0;
    for (let i = 0; i < tcrfee.length; i += 1) {
      totalBal += tcrfee[i];
    }
    return totalBal;
  };

  const tcrfeet = tcrfinalfee(tcrfee);
  const tcr = tcrfinalpay(tcrpay);

  //Angamali pay

  const angamali = users.filter((e) => {
    return e.username === "Angamali";
  });
  const angpay = angamali.map((e) => {
    return e.paybalance;
  });

  const angfee = angamali.map((e) => {
    return e.feepaid;
  });
  const angfinalpay = () => {
    let totalBal = 0;
    for (let i = 0; i < angpay.length; i += 1) {
      totalBal += angpay[i];
    }
    return totalBal;
  };
  const angfinalfee = () => {
    let totalBal = 0;
    for (let i = 0; i < angfee.length; i += 1) {
      totalBal += angfee[i];
    }
    return totalBal;
  };

  const ang = angfinalpay(angpay);
  const angfeet = angfinalfee(angfee);
  //Kaladi pay
  const kalady = users.filter((e) => {
    return e.username === "Kalady";
  });
  const kalpay = kalady.map((e) => {
    return e.paybalance;
  });
  const kalfee = kalady.map((e) => {
    return e.feepaid;
  });
  const kalfinalpay = () => {
    let totalBal = 0;
    for (let i = 0; i < kalpay.length; i += 1) {
      totalBal += kalpay[i];
    }
    return totalBal;
  };
  const kalfinalfee = () => {
    let totalBal = 0;
    for (let i = 0; i < kalfee.length; i += 1) {
      totalBal += kalfee[i];
    }
    return totalBal;
  };

  const kalfeet = kalfinalfee(kalfee);
  const kal = kalfinalpay(kalpay);

  //Pala pay

  const pala = users.filter((e) => {
    return e.username === "Pala";
  });
  const palpay = pala.map((e) => {
    return e.paybalance;
  });
  const palfinalpay = () => {
    let totalBal = 0;
    for (let i = 0; i < palpay.length; i += 1) {
      totalBal += palpay[i];
    }
    return totalBal;
  };
  const palfee = pala.map((e) => {
    return e.feepaid;
  });
  const palfinalfee = () => {
    let totalBal = 0;
    for (let i = 0; i < palfee.length; i += 1) {
      totalBal += palfee[i];
    }
    return totalBal;
  };

  const palfeet = palfinalfee(palfee);
  const pal = palfinalpay(palpay);

  //Italy

  const Italy = users.filter((e) => {
    return e.username === "Italy";
  });
  const itapay = Italy.map((e) => {
    return e.paybalance;
  });
  const itafinalpay = () => {
    let totalBal = 0;
    for (let i = 0; i < itapay.length; i += 1) {
      totalBal += itapay[i];
    }
    return totalBal;
  };
  const itafee = Italy.map((e) => {
    return e.feepaid;
  });
  const itafinalfee = () => {
    let totalBal = 0;
    for (let i = 0; i < itafee.length; i += 1) {
      totalBal += itafee[i];
    }
    return totalBal;
  };

  const itafeet = itafinalfee(itafee);
  const ita = itafinalpay(itapay);

  // Malta

  const malta = users.filter((e) => {
    return e.username === "Malta";
  });
  const malpay = malta.map((e) => {
    return e.paybalance;
  });
  const malfinalpay = () => {
    let totalBal = 0;
    for (let i = 0; i < malpay.length; i += 1) {
      totalBal += malpay[i];
    }
    return totalBal;
  };
  const malfee = malta.map((e) => {
    return e.paybalance;
  });
  const malfinalfee = () => {
    let totalBal = 0;
    for (let i = 0; i < malfee.length; i += 1) {
      totalBal += malfee[i];
    }
    return totalBal;
  };

  const malfeet = malfinalfee(malfee);
  const mal = malfinalpay(malpay);

  return (
    <div className={classes.adminCenter}>
      <div>
        <AdminNavbar />
      </div>
      <div className={classes.containerHome}>
        {/* <div className={classes.datePicker}>
          <form>
            <div className={classes.date}>
              <input type="date"></input>
            </div>
            <div className={classes.date}>
              <input type="date"></input>
            </div>
            <div className={classes.action}>
              <Button>Search</Button>
            </div>
          </form>
        </div> */}
        <div className={classes.cardContainer}>
          <AdminCard name={"Thrissur"} bal={tcr} fee={tcrfeet} />
          <AdminCard name={"Angamali"} bal={ang} fee={angfeet} />
          <AdminCard name={"Ettumanoor"} bal={kal} fee={kalfeet} />
          <AdminCard name={"Adimali"} bal={pal} fee={palfeet} />
          <AdminCard name={"Italy"} bal={ita} fee={itafeet} />
          <AdminCard name={"Malta"} bal={mal} fee={malfeet} />
        </div>
      </div>
    </div>
  );
};

export default AdminCenters;
